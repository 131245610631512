import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./EmailTemplate.module.css";
import useAxiosWrapper from "../../api/axiosWrapper";
import parse from "html-react-parser";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { saveAs } from "file-saver";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";

export default function EmailTemplate() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [error, setError] = useState(null);
  const axiosInstance = useAxiosWrapper();
  const [loading, setLoading] = useState(false);
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const [aiGeneratedImg, setAiGeneratedImg] = useState(null);
  const [aiSpinner, setAiSpinner] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedTone, setSelectedTone] = useState("neutral");
  const {
    selectedSuggestion,
    selectedTheme,
    selectedCharity,
    selectedIdea,
    username,
  } = useContext(UtilContext);
  console.log(aiGeneratedImg, "aiGeneratedImg");
  useEffect(() => {
    if (!username) {
      navigate("/");
      return;
    }
    if (
      !selectedTheme ||
      !selectedCharity ||
      !selectedSuggestion ||
      !selectedIdea
    ) {
      navigate("/");
      return;
    }
    fetchData();
  }, []);

  const toggle = () => setModal(!modal);

  const fetchData = async () => {
    setLoading(true);
    const url = process.env.REACT_APP_DO_GOOD_URL + "/campaign/email/template";

    const body = {
      // event: selectedSuggestion,
      // theme: selectedTheme,
      // charity_id: selectedCharity?.id,
      idea: selectedIdea,
    };

    try {
      const response = await axiosInstance.post(url, body);

      const events = response?.data?.data?.email;
      setEmailTemplate(events);
      setEmailId(response?.data?.data?.id);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const sendEmail = async () => {
    const url = process.env.REACT_APP_DO_GOOD_URL + "/campaign/email/send";
    const body = {
      recipe_id: emailId,
      image_url: selectedImg,
    };
    setLoading(true);
    try {
      await axiosInstance.post(url, body);
      setLoading(false);
      navigate("/complete");
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const downloadImage = () => {
    saveAs(
      selectedImg ? selectedImg : emailTemplate?.email_images?.[0]?.original,
      "image.jpg"
    ); // Put your image URL here.
  };

  const onChangeTemplate = (index) => {
    console.log(index, "current index");
    setSelectedImgIndex(index);
  };

  const fetchAiImages = async () => {
    setAiSpinner(true);
    const url = process.env.REACT_APP_DO_GOOD_URL + "/campaign/email/dalle";

    const body = {
      idea: selectedIdea,
    };

    try {
      const response = await axiosInstance.post(url, body);
      const events = response?.data?.data?.email;
      setAiGeneratedImg(events?.dalle_images);
      setLoading(false);
      setAiSpinner(false);
    } catch (error) {
      setError(error.message);
      setAiSpinner(false);
    }
  };

  const confirmImageHandler = () => {
    console.log(aiGeneratedImg, "aiGeneratedImg");
    if (!!aiGeneratedImg?.length) {
      setSelectedImg(aiGeneratedImg[0]);
    } else {
      setSelectedImg(emailTemplate?.email_images[selectedImgIndex]?.original);
    }
    setModal(false);
  };

  const copyToClipboard = async () => {
    const contentElement = document.getElementById("blog-content");

    if (!contentElement) return;

    // Clone the content to avoid modifying the original
    const clonedContent = contentElement.cloneNode(true);

    // Resize images
    const images = clonedContent.getElementsByTagName("img");
    for (let img of images) {
      img.style.width = "100%"; // Adjust as needed
      img.style.height = "auto"; // Maintain aspect ratio
    }

    // Convert modified content to HTML string
    const modifiedContent = clonedContent.innerHTML;

    try {
      if (navigator.clipboard && window.ClipboardItem) {
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([modifiedContent], { type: "text/html" }),
            "text/plain": new Blob([clonedContent.innerText], {
              type: "text/plain",
            }),
          }),
        ]);
        alert("Copied!");
      } else {
        // Fallback for older browsers / Android issues
        const textarea = document.createElement("textarea");
        textarea.value = clonedContent.innerText; // Plain text fallback
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        alert("Copied (fallback)!");
      }
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center h-100">
        <div onClick={() => navigate(-1)} className="back" style={{ top: 50 }}>
          <FaAngleLeft />
        </div>
        {loading ? (
          <div style={{ marginTop: 100, textAlign: "center" }}>
            <Spinner />
          </div>
        ) : (
          <>
            <Row className="justify-content-center">
              <Col sm={12} md={10}>
                <div className={classes.heroImg}>
                  <img
                    src={
                      selectedImg
                        ? selectedImg
                        : emailTemplate?.email_images?.[0]?.original
                    }
                    alt=""
                    className={classes.img}
                  />
                  <Button
                    onClick={() => setModal(true)}
                    style={{
                      position: "absolute",
                      bottom: 20,
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    View Image Options
                  </Button>
                  <Button
                    onClick={downloadImage}
                    className={classes.downloadBtn}
                  >
                    <AiOutlineCloudDownload size={22} />
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={10} className={classes.ctr}>
                Subject Line: {emailTemplate?.title}
                <div className="mt-3">
                  {parse(`${emailTemplate?.body}`)}
                  {/* {emailTemplate?.body?.split("\n").map(function (item, index) {
                    return (
                      <div key={index}>
                        {item}
                        <br />
                      </div>
                    );
                  })} */}
                </div>
                {/* <button
                  className="btn btn-dark mt-4 "
                  onClick={() => {
                    sendEmail();
                  }}
                >
                  Get Content
                </button> */}
                <div>
                  {/* <CopyToClipboard
                    text={emailTemplate?.body.replace(/<[^>]+>/g, "")}
                  > */}
                  <Button
                    color="link"
                    className="my-3"
                    onClick={copyToClipboard}
                  >
                    <MdContentCopy size={26} className="mr-3" />
                    Copy
                  </Button>
                  {/* </CopyToClipboard> */}
                </div>
              </Col>
              <Row
                className="justify-content-center align-items-center p-5"
                style={{ backgroundColor: "#eff0f6" }}
              >
                {/* <Col
                  sm={12}
                  md={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h6>Customize your tone</h6>
                </Col>
                <Col sm={12} md={12} className="d-flex justify-content-center">
                  <Row
                    xs={8}
                    md={12}
                    className="justify-content-center m-4 gap-3"
                  >
                    <Col xs={12} md={2} className="text-center">
                      <Button
                        outline={selectedTone === "neutral" ? false : true}
                        onClick={() => setSelectedTone("neutral")}
                      >
                        Neutral
                      </Button>
                    </Col>

                    <Col xs={12} md={2} className="text-center">
                      <Button
                        outline={selectedTone === "friendly" ? false : true}
                        onClick={() => setSelectedTone("friendly")}
                      >
                        Friendly
                      </Button>
                    </Col>
                    <Col xs={12} md={2} className="text-center">
                      <Button
                        outline={selectedTone === "casual" ? false : true}
                        onClick={() => setSelectedTone("casual")}
                      >
                        Casual
                      </Button>
                    </Col>
                    <Col xs={12} md={2} className="text-center">
                      <Button
                        outline={selectedTone === "inspiring" ? false : true}
                        onClick={() => setSelectedTone("inspiring")}
                      >
                        Inspiring
                      </Button>
                    </Col>
                    <Col xs={12} md={2} className="text-center">
                      <Button
                        outline={selectedTone === "urgent" ? false : true}
                        onClick={() => setSelectedTone("urgent")}
                      >
                        Urgent
                      </Button>
                    </Col>
                  </Row>
                </Col> */}
                <Col md={4} className="text-center">
                  <button
                    className="btn btn-secondary"
                    style={{ backgroundColor: "#ff5344" }}
                    onClick={() => {
                      sendEmail();
                    }}
                  >
                    Send Email
                  </button>
                </Col>
              </Row>
            </Row>
            {/* for copy paste */}
            <div style={{ display: "none" }}>
              <Row className="justify-content-center" id="blog-content">
                <Col sm={12} md={10}>
                  <div className={classes.heroImg}>
                    <img
                      src={
                        selectedImg
                          ? selectedImg
                          : emailTemplate?.email_images?.[0]?.original
                      }
                      alt=""
                      className={classes.img}
                    />
                  </div>
                </Col>
                <Col xs={12} md={10} className={classes.ctr}>
                  Subject Line: {emailTemplate?.title}
                  <div className="mt-3">
                    {parse(`${emailTemplate?.body}`)}
                    {/* {emailTemplate?.body?.split("\n").map(function (item, index) {
                    return (
                      <div key={index}>
                        {item}
                        <br />
                      </div>
                    );
                  })} */}
                  </div>
                  {/* <button
                  className="btn btn-dark mt-4 "
                  onClick={() => {
                    sendEmail();
                  }}
                >
                  Get Content
                </button> */}
                </Col>
              </Row>
            </div>
          </>
        )}
      </Row>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="imagesModal"
        onClosed={() => {
          setAiGeneratedImg(null);
        }}
        // backdrop={backdrop}
        // keyboard={keyboard}
      >
        <ModalBody>
          <h5 className="my-5">Recommended Images</h5>
          {!aiGeneratedImg?.length ? (
            <Carousel onChange={onChangeTemplate}>
              {emailTemplate?.email_images?.map((item) => {
                return (
                  <img
                    src={item?.original}
                    alt={item}
                    key={item}
                    style={{ maxHeight: 200, objectFit: "cover" }}
                  />
                );
              })}
            </Carousel>
          ) : (
            <>
              {aiGeneratedImg?.map((item) => {
                return (
                  <img
                    src={item}
                    alt={item}
                    key={item}
                    style={{ maxHeight: 200, marginBottom: 20 }}
                  />
                );
              })}
            </>
          )}
          <Row className="justify-content-center">
            <Col xs={3}>
              <Button
                outline
                onClick={() => {
                  setModal(false);
                  setAiGeneratedImg(null);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={3} onClick={confirmImageHandler}>
              <Button className="bg-dark">Confirm</Button>
            </Col>
          </Row>
          {!aiGeneratedImg?.length && (
            <Row className="justify-content-center mt-5">
              <Col xs={8}>
                <Button
                  disabled={aiSpinner}
                  outline
                  className="w-100 mt-3"
                  onClick={() => {
                    fetchAiImages();
                  }}
                >
                  {aiSpinner ? <Spinner size="small" /> : "Create AI Image"}
                </Button>
                <p className="mt-3">Use AI to generate a new image</p>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
}
